import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { isNil, isNumber, isString } from 'lodash-es';
import {
  distinctUntilChanged,
  EMPTY,
  filter,
  first,
  map,
  mergeMap,
  Observable,
  switchMap,
} from 'rxjs';

import { ISelectOption } from '@locumsnest/components/src';
import { PaginatedStateService, Query } from '@locumsnest/core';
import { HttpApiPersistenceService } from '@locumsnest/core/src';

import { LocumsNestEndpointConfig, SECTOR_CODES } from '../../core/constants';
import {
  hospitalPaginationSelectors,
  selectAllHospitals,
  selectAllowBackdatedShifts,
  selectAssignedHospital,
  selectAssignedHospitalControlContactOfficer,
  selectAssignedHospitalId,
  selectAssignedHospitalName,
  selectAssignedHospitalSector,
  selectAssignedHospitalSpiProVersion,
  selectAssignedHospitalStaffBankCollaborationGroups,
  selectHasRestrictedOfficers,
  selectHideBidRates,
  selectHospitalByIds,
  selectHospitalDetails,
  selectHospitalEntityState,
  selectHospitalSector,
  selectHospitalShiftLockRates,
  selectHospitalTimesheetLockRates,
  selectUsesAgency,
  selectUsesAgencyInvoicing,
  selectUsesCostCodesV2,
  selectUseTimeSheetRateViolationReason,
} from '../../hospital/+state/hospital.selectors';
import {
  IHospitalConfigurationEntity,
  IHospitalEntity,
  IHospitalShiftLockRates,
  IHospitalTimesheetLockRates,
} from '../../interfaces/api/hospital-entity';
import { SectorService } from '../../sector/+state/sector.services';
import { paginationAdapter } from './hospital.adapter';
import {
  hospitalPaginationMessages,
  ResetHospitalPaginationMessage,
  SetAssignedMessage,
  UpdateConfigurationMessage,
  UpsertHospitalPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './hospital.messages';
import { selectHospitalOptions, selectHospitalOptionsWithUuid } from './hospital.selectors';

@Injectable({
  providedIn: 'root',
})
export class HospitalPersistenceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  IHospitalEntity
> {
  protected readonly endpoint = 'hospital';
}

@Injectable({
  providedIn: 'root',
})
export class HospitalService extends PaginatedStateService<
  IHospitalEntity,
  UpsertHospitalPageMessage,
  ResetHospitalPaginationMessage,
  UpsertMultipleMessage
> {
  constructor(
    protected store: Store,
    protected persistenceService: HospitalPersistenceService,
    private sectorService: SectorService,
  ) {
    super();
  }
  get paginationMessages() {
    return hospitalPaginationMessages;
  }

  get paginationSelectors() {
    return hospitalPaginationSelectors;
  }

  get entityStateSelector() {
    return selectHospitalEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return paginationAdapter.getLoadingMessages();
  }

  getAll() {
    return this.store.pipe(select(selectAllHospitals));
  }

  getAllOptions() {
    return this.store.pipe(select(selectHospitalOptions));
  }

  getAllOptionsWithUuid() {
    return this.store.pipe(select(selectHospitalOptionsWithUuid));
  }

  getOptionsByIds(ids: number[]): Observable<ISelectOption[]> {
    return this.getByIds(ids).pipe(
      map((hospitals) => hospitals.map((hospital) => ({ id: hospital.id, name: hospital.name }))),
    );
  }

  getAssigned() {
    return this.store.pipe(
      select(selectAssignedHospital),
      filter((x) => !!x),
    );
  }

  getAssignedSpiProVersion() {
    return this.store.pipe(select(selectAssignedHospitalSpiProVersion));
  }

  getAssignedHospitalStaffBankCollaborationGroups() {
    return this.store.pipe(select(selectAssignedHospitalStaffBankCollaborationGroups));
  }

  getAssignedHasRestrictedOfficers(): Observable<boolean> {
    return this.store.pipe(select(selectHasRestrictedOfficers));
  }

  getAssignedBlurBidRates(): Observable<boolean> {
    return this.store.pipe(select(selectHideBidRates));
  }

  getUsesAgencyInvoicingValue() {
    return this.store.selectSignal(selectUsesAgencyInvoicing);
  }

  getAssignedName() {
    return this.store.pipe(
      select(selectAssignedHospitalName),
      filter((x) => !!x),
    );
  }

  getAssignedCrossCoveringEnabled() {
    return this.getAssigned().pipe(map((hospital) => hospital.shiftCrossCoveringEnabled));
  }

  getByIds(ids: number[]) {
    return this.store.pipe(select(selectHospitalByIds(ids)));
  }

  getHospitalDetails() {
    return this.store.pipe(select(selectHospitalDetails));
  }

  loadByIds(ids: number[]) {
    return this.fetch({ id: [...new Set(ids)] });
  }

  loadActiveHospitals() {
    return this.fetch({ active: 'true' });
  }

  getHospitalSector(hospitalId: number) {
    return this.store.pipe(
      select(selectHospitalSector(hospitalId)),
      mergeMap((hospitalSector) => this.sectorService.getOne(hospitalSector)),
    );
  }

  getAssignedHospitalSector() {
    return this.store.pipe(
      select(selectAssignedHospitalSector),
      distinctUntilChanged(),
      mergeMap((hospitalSector) => this.sectorService.getOne(hospitalSector)),
      filter((sector) => !!sector),
    );
  }

  getAssignedHospitalControlContactOfficer() {
    return this.store.pipe(select(selectAssignedHospitalControlContactOfficer));
  }

  isAssignedToPrimaryCareHospital(): Observable<boolean> {
    return this.getAssignedHospitalSector().pipe(
      map((sector) => sector.code === SECTOR_CODES.PRIMARY_CARE),
    );
  }

  isAssignedToVeterinaryHospital(): Observable<boolean> {
    return this.getAssignedHospitalSector().pipe(map((sector) => sector.code === SECTOR_CODES.VET));
  }

  loadCurrent() {
    return this.fetchCurrent();
  }

  fetchCurrent() {
    return this.persistenceService.retrieveCurrent<IHospitalEntity>().pipe(
      mergeMap((result) => {
        let currentId = null;

        if (result) {
          currentId = result.id;
        }
        return [
          new UpsertOneMessage({ entity: result }),
          new SetAssignedMessage({ id: currentId }),
        ];
      }),
    );
  }

  updatePartially(data: Partial<IHospitalEntity>) {
    return this.persistenceService
      .patch('my', data)
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  getAssignedHospitalId() {
    return this.store.pipe(
      select(selectAssignedHospitalId),
      filter((x) => !!x),
    );
  }

  updateCurrentConfigurationPartially(data): Observable<UpdateConfigurationMessage> {
    return this.getAssignedHospitalId().pipe(
      first(),
      mergeMap((hospitalId) => this.updateConfigurationPartially(hospitalId, data)),
    );
  }

  updateConfigurationPartially(
    hospitalId: number,
    data: Partial<IHospitalConfigurationEntity>,
  ): Observable<UpdateConfigurationMessage> {
    return this.persistenceService
      .patch<Partial<IHospitalConfigurationEntity>>('', data, undefined, {
        controllerResource: 'hospitalSettings',
        pathParams: { hospitalId },
      })
      .pipe(
        map(
          (entity: IHospitalConfigurationEntity) =>
            new UpdateConfigurationMessage({ id: hospitalId, entity }),
        ),
      );
  }

  loadOneIfNotExists(id: number): Observable<Action> {
    return this.getOneOrNone(id).pipe(
      first(),
      mergeMap((x) => {
        if (!x) {
          return this.loadOne(id);
        }
        return EMPTY;
      }),
    );
  }

  loadManyIfNotExists(ids: number[]): Observable<Action> {
    return this.getEntityDict().pipe(
      switchMap((hospitals) => {
        const uniqueIds = [...new Set(ids)];
        const idsToLoad: number[] = [];
        uniqueIds.forEach((id) => {
          if (!hospitals[id]) idsToLoad.push(id);
        });

        if (idsToLoad.length) return this.loadByIds(uniqueIds);
        return EMPTY;
      }),
    );
  }

  loadOne(id: number) {
    return this.fetch(id);
  }

  fetch(query?: Query) {
    if (isNil(query)) {
      return this.loadAllPages('default', {});
    }
    if (isString(query) || isNumber(query))
      return this.persistenceService
        .retrieve(query)
        .pipe(map((entity) => new UpsertOneMessage({ entity })));
    return this.persistenceService
      .retrieve(query)
      .pipe(map(({ results }) => new UpsertMultipleMessage({ entities: results })));
  }

  getHospitalShiftLockRates(): Observable<IHospitalShiftLockRates> {
    return this.store.pipe(select(selectHospitalShiftLockRates));
  }

  getHospitalTimesheetLockRates(): Observable<IHospitalTimesheetLockRates> {
    return this.store.pipe(select(selectHospitalTimesheetLockRates));
  }

  getUseTimeSheetRateViolationReason(): Observable<boolean> {
    return this.store.pipe(select(selectUseTimeSheetRateViolationReason));
  }

  getHospitalAllowBackdatedShifts(): Observable<boolean> {
    return this.store.pipe(select(selectAllowBackdatedShifts));
  }

  getHospitalUsesAgency(): Observable<boolean> {
    return this.store.pipe(select(selectUsesAgency));
  }

  getHospitalUsesCostCodesV2(): Observable<boolean> {
    return this.store.pipe(select(selectUsesCostCodesV2));
  }
}
